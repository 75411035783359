import React from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import {Section, Container, SectionImageBkg, SectionTitle, SectionDescription, SectionSubTitle, BreadCrumb, MarkdownContent } from "../components/Section"
import { HeroBanner, HeroTitle, HeroCaption, HeroContent, HeroLeft, HeroRight, HeroCard } from "../components/HeroBanner"
import { CreditCheckIcon, LowPaymentIcon, FlexiblePayIcon, PayOffIcon, InstantApprovalIcon } from "../components/Icons"
import JoinForm from "../components/JoinForm"

const SectionImageBkgs = styled(SectionImageBkg)`
  width: auto;
  height: auto;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  display:none;
  @media(min-width:1600px){
    display:block;
    right: -15%;
  }
  @media(min-width:1900px){
    right: -10%;
  }
`


const GridIcon = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom:30px;
`
const GridItemIcon = styled.div`
  position: relative;
  width: 100%;
  padding:0 40px;
  @media(min-width:576px){
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media(min-width:992px){
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`
const CardIcon = styled.div`
  position:relative;
 
  margin-bottom:20px;
  @media(min-width:768px){
    margin-bottom:30px;
  }
  @media(min-width:1200px){
    margin-bottom:60px;
  }
  &:before{
    content:'';
    position:absolute;
    left:5px;
    top:5px;
    border:1px solid #161420;
    width:100%;
    height:100%;
    border-radius:15px;
  }
  
`

const CardCircle = styled.div`
  width:68px;
  height:68px;
  border-radius:50%;
  border:7px solid #fff;

  background: #64e4f3;
  background: -moz-linear-gradient(top,  #64e4f3 0%, #278aff 100%);
  background: -webkit-linear-gradient(top,  #64e4f3 0%,#278aff 100%);
  background: linear-gradient(to bottom,  #64e4f3 0%,#278aff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#64e4f3', endColorstr='#278aff',GradientType=0 );
  box-shadow:0 6px 12px rgba(49,146,254,0.32);
  
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;  
  z-index:1;
  left: -40px;
  top:50%;
  transform: translateY(-50%);
  > svg{
    fill:#fff;
    width:34px;
  }

`
const CardDescIcon = styled.div` 
  position:relative;
  background: #F3F6FC;
  background: -moz-linear-gradient(-45deg,  #F3F6FC 0%, #EAEFF7 100%);
  background: -webkit-linear-gradient(-45deg,  #F3F6FC 0%,#EAEFF7 100%);
  background: linear-gradient(135deg,  #F3F6FC 0%,#EAEFF7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F3F6FC', endColorstr='#EAEFF7',GradientType=1 );
  border-radius:15px;
  padding:20px 15px 20px 40px;
  @media(min-width:768px){
    padding:20px 20px 20px 50px;
  }
`

const FinancingPageTemplate = ({ data, location }) => {
  const pageData = data.contentfulFinancingPages
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <Section bgColor="#F8FAFB" bgBefore="#002765" bgBeforeHeight="80%"  bgBeforeWidth="100%">
        <HeroBanner className="banner-hero" bgBefore="block" data-shadowtext={pageData.heroTitle}>
          <HeroCaption>
            <Container maxWidth="1640px">
              <BreadCrumb><Link to='/'>Home</Link><span>{pageData.heroTitle.indexOf("FINANCING") > -1 ? "Financing":"RTO"}</span></BreadCrumb>
              <HeroContent>
                <HeroLeft><HeroTitle>{pageData.heroTitle}</HeroTitle></HeroLeft>
                <HeroRight><HeroCard><JoinForm location={location} /></HeroCard></HeroRight>
              </HeroContent>
            </Container>
          </HeroCaption>
        </HeroBanner>
      </Section>
      <Section ept="80px" epb="120px" xpt="60px" xpb="80px" pt="40px" pb="40px"  bgColor="#F8FAFB" overflow="hidden"  className="section-about">
          <SectionImageBkgs>
            <GatsbyImage image={pageData.contentSectionImage.gatsbyImageData} alt={pageData.contentSectionImage.title} />
          </SectionImageBkgs>
          <Container maxWidth="1280px">
            <MarkdownContent>
              <div dangerouslySetInnerHTML={{__html: pageData.mainContent.childMarkdownRemark.html}} />
            </MarkdownContent>

            <SectionSubTitle className="h4 mb-0"><span className="letter-spacing-3">{pageData.featuresLabel}</span></SectionSubTitle>
            <SectionTitle mb="30px">{pageData.featuresTitle}</SectionTitle>
            {pageData.url === "/rto" ? (
              <GridIcon>
                <GridItemIcon>
                  <CardIcon className="card-icon"><CardCircle className="card-circle"><CreditCheckIcon /></CardCircle><CardDescIcon>No credit check</CardDescIcon></CardIcon>
                </GridItemIcon>
                <GridItemIcon>
                  <CardIcon className="card-icon"><CardCircle className="card-circle"><LowPaymentIcon /></CardCircle><CardDescIcon>Low down payment</CardDescIcon></CardIcon>
                </GridItemIcon>
                <GridItemIcon>
                  <CardIcon className="card-icon"><CardCircle className="card-circle"><FlexiblePayIcon /></CardCircle><CardDescIcon>Flexible payment terms</CardDescIcon></CardIcon>
                </GridItemIcon>
                <GridItemIcon>
                  <CardIcon className="card-icon"><CardCircle className="card-circle"><PayOffIcon /></CardCircle><CardDescIcon>Payoff with no penalty</CardDescIcon></CardIcon>
                </GridItemIcon>
                <GridItemIcon>
                  <CardIcon className="card-icon"><CardCircle className="card-circle"><InstantApprovalIcon /></CardCircle><CardDescIcon>24/7 instant approval</CardDescIcon></CardIcon>
                </GridItemIcon>
              </GridIcon>
            ):(
              <SectionDescription mb="30px">
                <ul className="column-two">
                  {pageData.features.map((feature, i) => (
                    <li key={i}>{feature.content}</li>
                  ))}
                </ul>
              </SectionDescription>
            )}
            <SectionSubTitle className="h4 mb-0"><span className="letter-spacing-3">{pageData.conditionsLabel}</span></SectionSubTitle>
            <SectionTitle mb="30px">{pageData.coditionsTitle}</SectionTitle>
            <SectionDescription mb="80px">
              <ul>
                {pageData.conditions.map((condition, i) => (
                  <li key={i}>{condition.content}</li>
                ))}
              </ul>
            </SectionDescription>
            <MarkdownContent>
              <div dangerouslySetInnerHTML={{__html: pageData.content.childMarkdownRemark.html}} />
            </MarkdownContent>
          </Container>
      </Section>
    </Layout>
  )
}

export default FinancingPageTemplate

export const pageQuery = graphql`
  query FinancingPageTemplateQuery($id: String!) {
    contentfulFinancingPages(id: { eq: $id }) {
      metaTitle
      metaDescription
      url
      heroTitle
      mainContent {
        childMarkdownRemark {
          html
        }
      }
      featuresLabel
      featuresTitle
      features {
        content
      }
      conditionsLabel
      coditionsTitle
      conditions {
        content
      }
      contentSectionImage {
        title
        gatsbyImageData(quality: 50, placeholder: BLURRED)
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`